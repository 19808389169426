/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "ap-southeast-1",
    "aws_appsync_graphqlEndpoint": "https://iwcgwfrsvreplnda7mbocgb2pi.appsync-api.ap-southeast-1.amazonaws.com/graphql",
    "aws_appsync_region": "ap-southeast-1",
    "aws_appsync_authenticationType": "API_KEY",
    "aws_appsync_apiKey": "da2-hdsuu4famvdohh2rsrqmrwwi4i",
    "aws_cognito_identity_pool_id": "ap-southeast-1:5f23bcbc-4828-453f-8cf0-b5f75c265956",
    "aws_cognito_region": "ap-southeast-1",
    "oauth": {},
    "aws_cognito_username_attributes": [],
    "aws_cognito_social_providers": [],
    "aws_cognito_signup_attributes": [],
    "aws_cognito_mfa_types": [],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyCharacters": []
    },
    "aws_cognito_verification_mechanisms": [],
    "aws_content_delivery_bucket": "fwd-mentalhealth-phprod",
    "aws_content_delivery_bucket_region": "ap-southeast-1",
    "aws_content_delivery_url": "https://d1lk4qhkotkr02.cloudfront.net"
};


export default awsmobile;
