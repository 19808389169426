import "regenerator-runtime/runtime";
import { API } from "aws-amplify";
import {
  updateCoupon,
} from "../graphql/mutations";
import { couponByClientId, listCoupons} from "../graphql/queries";

export const updateCouponWithClientId = async (clientId) => {
  let clientCoupon = await getClientCoupon(clientId)
  if (clientCoupon.length>0){
      return clientCoupon[0].coupon
  }
  let coupons = await getFreeCoupons()
  if (coupons.length>0){
    let couponForClient = coupons[0]
    let result = await API.graphql({
        query: updateCoupon,
        variables: {
          input: {
            clientId: clientId,
            isUsed: !couponForClient.isUsed,
            id:couponForClient.id
          }
        },
      });
    return result.data.updateCoupon.coupon
  }else{
    return 'All coupons are used. Please add new coupons'
  }
};

export const getAllCoupon = async () =>
  (
    await API.graphql({
      query: listCoupons,
    })
  ).data.listCoupons.items;

export const getFreeCoupons = async () =>
  (
    await API.graphql({
      query: listCoupons,
      variables:{
        filter:{
            isUsed:{
                eq: false
            }
          }
      }
    })
  ).data.listCoupons.items;


const getClientCoupon = async (clientId) =>
  (
    await API.graphql({
      query: couponByClientId,
      variables: {
        clientId: clientId,
      },
    })
  ).data.couponByClientId.items;
